import React from 'react'
import * as styles from './blog-excerpt.module.css'
import { Link } from 'gatsby'
import AgnosticLink from './AgnosticLink'
import { rhythm } from '../utils/typography'
import { maxItems } from '../utils/collections'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from './Button'
import Tag from './tags/Tag'
import { YouTube } from './YouTube'
import { getFormattedDate } from '../utils/formatting'


const SHORT_TAG = '<!-- short -->'
const MORE_TAG = '<!-- more -->'
const FALLBACK_IMAGE_ALT = 'The awesome barbarian meets coding logo'
const FALLBACK_IMAGE_TITLE = 'Barbarian Meets Coding'

export default class BlogExcerpt extends React.Component {
  render() {
    const { slug, title, date, html, categories = [], readingTime } = this.props
    const formattedDate = getFormattedDate(date)
    const hasShortExcerpt = html.includes(SHORT_TAG)
    const hasMoreExcerpt = html.includes(MORE_TAG)
    // Prefer short excerpts
    const htmlExcerpt = hasShortExcerpt
      ? html.split(SHORT_TAG)[0]
      : html.split(MORE_TAG)[0]
    const hasContent = hasShortExcerpt || hasMoreExcerpt
    // TODO(vintharas): should fallback to a nice image per keyword
    // e.g. typescript, vim, javascript, personal, etc...
    // and if there aren't any, then just use the super fallback fallback
    // (could I try just generating an image with SVG?)
    const coverImage = {
      image: this.props.coverImage,
      alt: this.props.coverImageAlt ?? FALLBACK_IMAGE_ALT,
      title: this.props.coverImageTitle ?? FALLBACK_IMAGE_TITLE,
      width: this.props.coverImageWidth ?? '100%',
    }
    const hasCoverImage = !!coverImage.image
    const video = this.props.video
    const useTwoColumnLayout = hasCoverImage || video

    return useTwoColumnLayout ? (
      <article className={styles.article}>
        <div className={styles.left}>
          {video ? (
            <YouTube {...{ video }} />
          ) : (
            <CoverImage {...{ slug, coverImage }} />
          )}
          <section className={styles.categories}>
            {categories &&
              maxItems(categories, 3).map((category, idx) => (
                <Tag key={idx}>{category}</Tag>
              ))}
          </section>
        </div>
        <div className={styles.right}>
          <header style={{ marginBottom: rhythm(1) }}>
            <div className={styles.metadata}>
              <time className={styles.date} style={{ display: 'none' }}>
                {formattedDate}
              </time>
              <span className={styles.readingtime} style={{ display: 'none' }}>
                {readingTime}
              </span>
            </div>
            <h1>
              <Link style={{ boxShadow: 'none' }} to={slug}>
                {title}
              </Link>
            </h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: htmlExcerpt }} />
          {hasContent && <Button to={slug}>Read on →</Button>}
        </div>
      </article>
    ) : (
      <article className={styles.article}>
        <div className={styles.textOnlyArticle}>
          <header style={{ marginBottom: rhythm(1) }}>
            <div className={styles.metadata}>
              <time className={styles.date} style={{ display: 'none' }}>
                {formattedDate}
              </time>
              <span className={styles.readingtime} style={{ display: 'none' }}>
                {readingTime}
              </span>
            </div>
            <section className={styles.categories}>
              {categories &&
                maxItems(categories, 3).map((category, idx) => (
                  <Tag key={idx}>{category}</Tag>
                ))}
            </section>
            <h1>
              <Link style={{ boxShadow: 'none' }} to={slug}>
                {title}
              </Link>
            </h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: htmlExcerpt }} />
          {hasContent && <Button to={slug}>Read on →</Button>}
        </div>
      </article>
    )
  }
}

const CoverImage = ({ slug, coverImage }) => {
  const image = getImage(coverImage.image)
  return (
    <AgnosticLink href={slug} className={styles.coverImageLink}>
      <GatsbyImage
        image={image}
        alt={coverImage.alt}
        title={coverImage.title}
      />
    </AgnosticLink>
  )
}
