import React from 'react'
import BlogLayout from '../components/layout/Layout'
import { HomeLayout } from '../components/layout/HomeLayout'
import * as styles from './BlogIndex.module.css'

import { graphql } from 'gatsby'
import { toReadingTime } from '../utils/formatting'
import get from 'lodash/get'
import BlogExcerpt from '../components/BlogExcerpt'
import SEO from '../components/seo'
import { ButtonPagination } from '../components/Button'

export default class BlogIndex extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges.map((e) => e.node)
    const { previous, next } = getPreviousAndNext(this.props.pageContext)

    const isHome = !previous
    const Layout = isHome ? HomeLayout : BlogLayout

    return (
      <Layout>
        <section>
          <SEO />
          {posts.map((post) => {
            // TODO(clean-up): Strange, is the title missing some times???
            const title = get(post, 'frontmatter.title') || post.fields.slug
            const props = {
              title,
              slug: post.fields.slug,
              html: post.html,
              readingTime: toReadingTime(post.timeToRead),
              date: post.frontmatter.date,
              categories: post.frontmatter.categories,
              coverImage: post.frontmatter.cover_image,
              coverImageAlt: post.frontmatter.cover_image_alt,
              coverImageTitle: post.frontmatter.cover_image_title,
              coverImageWidth: post.frontmatter.cover_image_width,
              video: post.frontmatter.video,
            }

            return <BlogExcerpt key={props.slug} {...props} />
          })}
          <footer>
            <ul className={styles.pagination}>
              {previous && (
                <li>
                  <ButtonPagination to={previous} rel="prev">
                    ← to the past!
                  </ButtonPagination>
                </li>
              )}

              <li className={styles.top}>
                <ButtonPagination onClick={() => scrollTo(0, 0)}>
                  ↑ To the Top ↑
                </ButtonPagination>
              </li>

              {next && (
                <li>
                  <ButtonPagination to={next} rel="next">
                    next →
                  </ButtonPagination>
                </li>
              )}
            </ul>
          </footer>
        </section>
      </Layout>
    )
  }
}

/*
PaginatedBlogIndexQuery: A query that returns paginated blog index results. The
$skip, $limit and $allowDrafts params are provided by the Gatsby context when
using createPage in gatsby-node.js

$skip and $limit are used for pagination
$removeDrafts is [true] for production and [] for development
*/
export const pageQuery = graphql`
  query PaginatedBlogIndexQuery(
    $skip: Int!
    $limit: Int!
    $removeDrafts: [Boolean]!
  ) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { nin: $removeDrafts } }
        fields: { isBlogArticle: { eq: true } }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            title
            date
            categories
            cover_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            cover_image_alt
            cover_image_title
            video
          }
          timeToRead
        }
      }
    }
  }
`

function getPreviousAndNext({ previousPagePath, nextPagePath }) {
  return { previous: previousPagePath, next: nextPagePath }
}
