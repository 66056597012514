// extracted by mini-css-extract-plugin
export var animate = "AboutHeader-module--animate--24362";
export var animationDuration = "3s";
export var avatar = "AboutHeader-module--avatar--64c51";
export var background = "AboutHeader-module--background--2ca07";
export var container = "AboutHeader-module--container--b4404";
export var highlight = "#4eff00";
export var image = "AboutHeader-module--image--ba233";
export var light = "AboutHeader-module--light--b4fcf";
export var marginHorizontal = "7vw";
export var marginSmallHorizontal = "2vw";
export var message = "AboutHeader-module--message--1b106";
export var pixelart = "AboutHeader-module--pixelart--824a4";
export var prompt = "AboutHeader-module--prompt--8876d";
export var text = "AboutHeader-module--text--6b741";
export var textHighlight = "#FF5722";
export var throne = "AboutHeader-module--throne--52761";