// extracted by mini-css-extract-plugin
export var articles = "Series-module--articles--19f5e";
export var badge = "Series-module--badge--06690";
export var black = "#11111f";
export var content = "Series-module--content--c9526";
export var green = "#00cc6d";
export var readMore = "Series-module--read-more--dadf2";
export var serie = "Series-module--serie--34ad3";
export var series = "Series-module--series--7f8d7";
export var seriesImageLink = "Series-module--series-image-link--50f94";
export var yellow = "Series-module--yellow--5f596";