import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as styles from './IntroHeader.module.css'
import { Audio } from '../audio/Audio'
import { IntroButton } from '../Button'

export const IntroHeader = () => {
  const [isUserReady, setIsUserReady] = useState(false)

  return (
    <section className={styles.container}>
      {isUserReady ? (
        <Intro />
      ) : (
        <ReadyForAdventure onUserIsReady={() => setIsUserReady(true)} />
      )}
    </section>
  )
}

const ReadyForAdventure = ({ onUserIsReady }) => {
  return (
    <section className={styles.areYouReady}>
      <p className={styles.salute} style={{ margin: 0 }}>
        Hello <span className={styles.highlightedText}>mighty hero</span>!
      </p>
      <p className={styles.question}>Are you ready to venture forth?</p>
      <button className={styles.imReadyBtn} onClick={() => onUserIsReady()}>
        I'm ready
      </button>
    </section>
  )
}

const Intro = () => (
  <>
    <Audio src={'/audio/anvil-of-crom.mp3'} />
    <section className={styles.background}></section>
    <article className={styles.welcome}>
      <p className={styles.text}>
        Between the time when the oceans drank Atlantis, and the rise of the
        sons of Aryas, there was{' '}
        <span className={styles.highlightedText}>an age undreamed of</span>.
      </p>
      <p className={styles.text}>
        And unto this, <span className={styles.highlightedText}>Jaime</span>,
        destined to bear the jeweled crown of Aquilonia upon a troubled brow.
      </p>
      <p className={styles.text}>
        It is I, his chronicler, who alone can tell thee of his{' '}
        <span className={styles.highlightedText}>saga</span>.
      </p>
      <p className={styles.text}>
        Let me tell you of the days of{' '}
        <span className={styles.highlightedText}>high adventure</span>!
      </p>
      {
        // <p>- Robert E. Howard (ish)</p>
      }
    </article>

    <section className={styles.banner}>
      <img
        src="/images/barbarian-meets-coding-new-title-v1-big-transparent.png"
        title="Barbarian Meets Coding"
        alt="Barbarian Meets Coding Title"
      />
    </section>
    <section className={`${styles.pixelart} ${styles.land}`}>
      <div className={styles.image}></div>
    </section>
    <section className={`${styles.pixelart} ${styles.temple}`}>
      <div className={styles.image}></div>
    </section>
    <section className={`${styles.pixelart} ${styles.cloudsRight}`}>
      <div className={styles.image}></div>
    </section>
    <section className={`${styles.pixelart} ${styles.cloudsLeft}`}>
      <div className={styles.image}></div>
    </section>
    <section className={`${styles.pixelart} ${styles.stars}`}>
      <div className={styles.image}></div>
    </section>
    <section className={`${styles.pixelart} ${styles.starsLeft}`}>
      <div className={styles.image}></div>
    </section>
    <section className={`${styles.pixelart} ${styles.starsRight}`}>
      <div className={styles.image}></div>
    </section>
    <img
      src="/images/pixelart/conan-axe.gif"
      className={styles.conan}
      alt="Conan wielding an axe"
    />
    <span className={styles.scrolldown}>▼ ▼ ▼</span>
  </>
)
