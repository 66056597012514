// extracted by mini-css-extract-plugin
export var animationDuration = "3s";
export var areYouReady = "IntroHeader-module--are-you-ready--fc24e";
export var background = "IntroHeader-module--background--2b85b";
export var banner = "IntroHeader-module--banner--ed0a5";
export var blink = "IntroHeader-module--blink--c6772";
export var cloudsLeft = "IntroHeader-module--clouds-left--e88a1";
export var cloudsRight = "IntroHeader-module--clouds-right--65fdd";
export var conan = "IntroHeader-module--conan--85869";
export var container = "IntroHeader-module--container--74c84";
export var fadeIn = "IntroHeader-module--fade-in--37421";
export var fadeInDown = "IntroHeader-module--fade-in-down--e007c";
export var fadeInOut = "IntroHeader-module--fade-in-out--edca8";
export var fadeInPicture = "IntroHeader-module--fade-in-picture--7ebb0";
export var fadeInSideways = "IntroHeader-module--fade-in-sideways--f1ec4";
export var fadeInUp = "IntroHeader-module--fade-in-up--a95a8";
export var fadeOut = "IntroHeader-module--fade-out--2e12c";
export var highlight = "#4eff00";
export var highlightedText = "IntroHeader-module--highlighted-text--77e46";
export var hover = "IntroHeader-module--hover--4ca82";
export var imReadyBtn = "IntroHeader-module--im-ready-btn--0cb58";
export var image = "IntroHeader-module--image--2936b";
export var land = "IntroHeader-module--land--b9e4c";
export var marginHorizontal = "7vw";
export var marginSmallHorizontal = "2vw";
export var moveLeft = "IntroHeader-module--move-left--d48b1";
export var moveRight = "IntroHeader-module--move-right--8afa1";
export var pixelart = "IntroHeader-module--pixelart--df575";
export var question = "IntroHeader-module--question--643fe";
export var quote = "IntroHeader-module--quote--5b29b";
export var quoteFont = "\"IM Fell DW Pica\", serif";
export var salute = "IntroHeader-module--salute--9a9c0";
export var scrolldown = "IntroHeader-module--scrolldown--1b028";
export var shadow = "IntroHeader-module--shadow--984e9";
export var shake = "IntroHeader-module--shake--ba39d";
export var stars = "IntroHeader-module--stars--d7cde";
export var starsLeft = "IntroHeader-module--stars-left--1c186";
export var starsRight = "IntroHeader-module--stars-right--81578";
export var temple = "IntroHeader-module--temple--586ee";
export var text = "IntroHeader-module--text--c4e1e";
export var textHighlight = "#FF5722";
export var twinkle = "IntroHeader-module--twinkle--614b3";
export var welcome = "IntroHeader-module--welcome--efefd";