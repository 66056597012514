import React, { ReactFragment } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import * as styles from './Featured.module.css'
import { Badge } from '../tags/Tag'
import Button from '../Button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SHORT_TAG = '<!-- short -->'
const MORE_TAG = '<!-- more -->'

export const Featured = () => {
  const { site, allMarkdownRemark } = useStaticQuery(
    graphql`
      query FeaturedArticle {
        site {
          siteMetadata {
            title
            author
          }
        }
        allMarkdownRemark(
          filter: {
            frontmatter: { title: { eq: "TypeScript Types Deep Dive" } }
          }
        ) {
          edges {
            node {
              id
              excerpt(format: HTML)
              timeToRead
              fields {
                slug
              }
              frontmatter {
                title
                date
                categories
                description
                cover_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                cover_image_alt
                cover_image_title
              }
            }
          }
        }
      }
    `
  )

  const [first] = allMarkdownRemark.edges
  const article = first.node
  const {
    frontmatter: {
      title,
      cover_image: coverImage,
      cover_image_alt: coverImageAlt,
      cover_image_title: coverImageTitle,
    },
    excerpt,
    fields: { slug },
  } = article

  // coverImage is going to be an ImageSharp node that represents an image.
  // The getImage helper returns a gatsbyImageData object for that node that
  // one can use with the GatsbyImage component.
  //
  // See https://www.gatsbyjs.com/docs/tutorial/part-7/ for more information.
  const image = getImage(coverImage)

  return (
    <article className={styles.article}>
      <header>
        <Badge>FEATURED</Badge>
      </header>
      {coverImage && (
        <Link to={slug} className={styles.coverImageLink}>
          <GatsbyImage
            image={image}
            alt={coverImageAlt}
            title={coverImageTitle}
            className={styles.featuredImage}
            imgClassName={styles.featuredImage}
          />
        </Link>
      )}
      <section className={styles.content}>
        <h1>
          <Link style={{ boxShadow: 'none' }} to={slug}>
            {title}
          </Link>
        </h1>
        <section dangerouslySetInnerHTML={{ __html: excerpt }} />
        <Button to={slug}>Read on →</Button>
      </section>
    </article>
  )
}
