import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Notes, Note } from './notes.js'
import * as styles from './LatestNotes.module.css'
import AgnosticLink from '../AgnosticLink.js'
import { StaticImage } from 'gatsby-plugin-image'

export const LatestNotes = () => {
  // TODO: Add support for mdx as well as markdown!!
  // TODO: Styler a bit better
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query LatestNotes {
        site {
          siteMetadata {
            title
            author
          }
        }
        allMarkdownRemark(
          limit: 6
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {
            frontmatter: { draft: { eq:false }}
            fields: { notes: { eq:true }} 
          }
        ) {
          edges {
            node {
              id
              excerpt(format: HTML)
              timeToRead
              fields {
                slug
              }
              frontmatter {
                title
                date
                categories
                description
                cover_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                cover_image_alt
                cover_image_title
              }
            }
          }
        }
      }
    `
  )

  const edges = allMarkdownRemark.edges
  const articles = edges.map(e => e.node)

  return (
    <article className={styles.latestNotes}>
      <h2>Latest Notes</h2>
      <p>As I experiment with new technologies, read interesting books, learn about thought provoking ideas I write <AgnosticLink href={'/notes'}>a lot of notes</AgnosticLink>. Often they aren't as polished as the articles in my blog but I find them really useful. You might do too, so take a look! Here are some of the latest ones...</p>
      <Notes wide spacious>
        {articles.map(a => <Note title={a.frontmatter.title} description={a.frontmatter.description} href={a.fields.slug} />)}
      </Notes>
    </article>
  )
}
