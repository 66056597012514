import React from 'react'
import * as styles from './NewHeader.module.css'

import AgnosticLink from '../AgnosticLink'
import Button from '../Button'
import { StaticImage } from 'gatsby-plugin-image'

export class NewHeader extends React.Component {
  render() {
    return (
      <section className={styles.container}>
        <aside className={styles.news}>
          <div className={styles.phoneOverlay}></div>
          <section className={styles.text}>
            <h1>NEW!!!</h1>
            <p>
              Unleash the power of Vim in Visual Studio Code,{' '}
              <span className={styles.highlightText}>
                boost your productivity
              </span>{' '}
              and become an even more{' '}
              <span className={styles.highlightText}>awesome developer</span>.
            </p>
            <div className={styles.titleBackground}></div>
            <h2>
              <span className={styles.boost}>Boost</span> Your Coding Fu With{' '}
              <span className={styles.vscode}>VSCode</span> and{' '}
              <span className={styles.vim}>Vim</span>
            </h2>
            <p className={styles.phoneHide}>
              A concise no-nonsense introduction to Vim in VSCode meant to get
              you over the initial hump and gliding into new lands of coding
              productivity.{' '}
              <span className={styles.highlightText}>Free to read online</span>{' '}
              and with{' '}
              <span className={styles.highlightText}>
                lots of supporting videos
              </span>
              .
            </p>
            <footer>
              <Button
                vscodevim
                pixelated
                to={'/boost-your-coding-fu-with-vscode-and-vim'}
              >
                Learn More!
              </Button>
            </footer>
          </section>
          <section className={styles.imageContainer}>
            <AgnosticLink href="/boost-your-coding-fu-with-vscode-and-vim">
              <StaticImage
                src="https://www.barbarianmeetscoding.com/images/boost-your-coding-fu-with-vscode-and-vim-3d-book-cover-no-shadow.png"
                alt="Boost Your Coding Fu with VSCode and Vim cover"
                title="Read Boosting Your Coding Fu with VSCode and Vim! Yey!"
                width={300}
                height={473}
                quality={100}
                className={styles.image}
                layout="fixed"
              />
            </AgnosticLink>
          </section>
        </aside>
      </section>
    )
  }
}
