import React from 'react'
import * as styles from './Audio.module.css'

export const Audio = ({ src }) => {
  return (
    <section className={styles.audioContainer}>
      <audio controls className={styles.audio} autoPlay>
        <source src={src} type="audio/mp3" />
      </audio>
    </section>
  )
}
