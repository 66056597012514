// extracted by mini-css-extract-plugin
export var boost = "NewHeader-module--boost--9f813";
export var container = "NewHeader-module--container--bfa73";
export var highlight = "#4eff00";
export var highlightText = "NewHeader-module--highlight-text--1dfd8";
export var image = "NewHeader-module--image--caba5";
export var imageContainer = "NewHeader-module--image-container--2ba03";
export var marginHorizontal = "7vw";
export var marginSmallHorizontal = "2vw";
export var news = "NewHeader-module--news--5d9fc";
export var phoneHide = "NewHeader-module--phone-hide--73b2f";
export var phoneOverlay = "NewHeader-module--phone-overlay--d0519";
export var text = "NewHeader-module--text--863b3";
export var textHighlight = "#FF5722";
export var titleBackground = "NewHeader-module--title-background--831c5";
export var vim = "NewHeader-module--vim--651b1";
export var vscode = "NewHeader-module--vscode--520e4";