// extracted by mini-css-extract-plugin
export var accent = "#ffb53b";
export var article = "blog-excerpt-module--article--82a49";
export var bordercolor = "#dedede";
export var categories = "blog-excerpt-module--categories--7cb26";
export var coverImageLink = "blog-excerpt-module--cover-image-link--f2bce";
export var date = "blog-excerpt-module--date--bff35";
export var footer = "blog-excerpt-module--footer--c09c1";
export var left = "blog-excerpt-module--left--a2c78";
export var metadata = "blog-excerpt-module--metadata--2c2cc";
export var readingtime = "blog-excerpt-module--readingtime--3f4b0";
export var right = "blog-excerpt-module--right--9739e";
export var textOnlyArticle = "blog-excerpt-module--textOnlyArticle--0cc67";