import React from 'react'
import { Link } from 'gatsby'
import * as styles from './Series.module.css'
import { StaticQuery, graphql } from 'gatsby'
import Button from '../Button'
import { Badge } from '../tags/Tag'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MAX_NUMBER_ARTICLES = 3

// TODO(vintharas): Use GatsbyImage by grabbing the images from the cover
// images of the articles. May need to add them, I think I likely need to
// configure the filesystem plugin as well

export const Series = () => (
  <aside className={styles.series}>
    <Serie
      name="javascriptmancy"
      title="JavaScript-mancy"
      subtitle="Master the Arcane Art of Writing JavaScript"
      description="A magical and fun blend of JavaScript and Fantasy. Learn to write JavaScript in the company of orcs, wizards, elves and dragons."
      image="/images/javascriptmancy-series-cover.jpg"
      imageAlt="A young wizard practices the fine wizardring arts with mixed success throwing fireballs at her master."
    />
    <Serie
      name="exploring-vim"
      title="Exploring Vim"
      subtitle="Venture forth in the exotic land of Vim"
      description="Follow along as I dive deep into the mysterious world of Vim and learn how to write code at the speed of thought."
      image="/images/become-1-percent-better.jpg"
      imageAlt="A text that reads: Become 1 percent better every day"
    />
    <Serie
      name="5-minutes-vim"
      title="5 Minutes Vim"
      subtitle="Learn Useful Vim Tricks in Under 5 Minutes"
      description="A great way to improve your Vim skills. Useful Vim tips and tricks in under 5 minutes."
      image="/images/5-minutes-vim.jpg"
      imageAlt="A text that reads: 5 minutes Vim"
    />
    <Serie
      name="typescript-introduction"
      title="Introduction to TypeScript"
      subtitle="JavaScript + Types = Awesome Developer Productivity"
      description="Discover TypeScript and find out how it can enhance your productivity and the productivity of your team."
      image="/images/typescript-official.png"
      imageAlt="The TypeScript word in white with a dark blue background."
    />
    <Serie
      name="typescript-types-deep-dive"
      title="TypeScript Types Deep Dive"
      subtitle="Unearth the mysteries of TypeScript type system"
      description="A journey of discovery through TypeScript's powerful type system, its mysteries and perils."
      image="/images/typescript-types-deep-dive.jpg"
      imageAlt="The TypeScript logo with the 'Types Deep Dive' sentence underneath using a pixelated font. Very nerdy."
    />
  </aside>
)

export const Serie = ({
  name,
  title,
  subtitle,
  description,
  image,
  imageAlt,
  theme = '',
}) => {
  const themed = (className) => `${className} ${styles[theme]}`

  return (
    <StaticQuery
      query={graphql`
        query SeriesQuery {
          site {
            siteMetadata {
              title
            }
          }
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: ASC }
            filter: {
              fields: { isBlogArticle: { eq: true } }
              frontmatter: { series: { ne: null } }
            }
            limit: 1000
          ) {
            edges {
              node {
                html
                fields {
                  slug
                  isBlogArticle
                }
                frontmatter {
                  title
                  date
                  categories
                  description
                  series
                  cover_image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  cover_image_alt
                  cover_image_title
                  cover_image_width
                  video
                }
                timeToRead
              }
            }
          }
        }
      `}
      render={(data) => {
        const articles = data.allMarkdownRemark.edges
          .map((e) => e.node)
          .filter((n) => n.frontmatter.series === name)
        const presentedArticles = articles.slice(0, MAX_NUMBER_ARTICLES)
        const articlesLeft = articles.length - MAX_NUMBER_ARTICLES
        // Pick the cover image from the first article
        const coverImage = presentedArticles[0].frontmatter.cover_image
        // coverImage is going to be an ImageSharp node that represents an image.
        // The getImage helper returns a gatsbyImageData object for that node that
        // one can use with the GatsbyImage component.
        //
        // See https://www.gatsbyjs.com/docs/tutorial/part-7/ for more information.
        const image = getImage(coverImage)

        return (
          <section className={themed(styles.serie)}>
            <Badge className={styles.badge}>SERIES</Badge>
            <h2>
              {title}
              <small>{subtitle}</small>
            </h2>
            <article className={styles.content}>
              <section>
                <Link to={`/series/${name}`} className={styles.seriesImageLink}>
                  <GatsbyImage image={image} alt={imageAlt} title={title} />
                </Link>
              </section>
              <section>
                <p>{description}</p>
              </section>
              <section className={styles.articles}>
                <h3>Articles in the series:</h3>
                <ol>
                  {presentedArticles.map((a, idx) => (
                    <li key={idx}>
                      <Link to={a.fields.slug} title={a.frontmatter.title}>{ellipsis(a.frontmatter.title)}</Link>
                    </li>
                  ))}
                  {articlesLeft > 0 && (
                    <li>
                      <Link to={`/series/${name}`} key={1000}>
                        {articlesMore(articlesLeft)}
                      </Link>
                    </li>
                  )}
                </ol>
                <Button
                  black
                  className={themed(styles.readMore)}
                  to={`/series/${name}`}
                >
                  Read More
                </Button>
              </section>
            </article>
          </section>
        )
      }}
    />
  )
}

const articlesMore = (articlesLeft) =>
  articlesLeft > 1 ? `${articlesLeft} articles more...` : `1 article more...`

const ellipsis = (text, length = 35) => text.length > length ? `${text.substring(0, length)}...` : text;
