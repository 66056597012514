import React from 'react'
import * as styles from './AboutHeader.module.css'
import { getRandomInteger } from '../../utils/random'
import { StaticImage } from 'gatsby-plugin-image'

const TITLE = 'HELLO TRAVELLER!'
const CONTENT =
  "I'm Jaime, King and guardian of everything your eyes can see. Welcome to my kingdom! In my domains, you'll find numerous teatrises, articles, tutorials and reflections on the arcane arts of JavaScript, Web Development, UX, Programming, Philosophy and Life. All with a little pinch of nerdiness and fantasy for extra fun and joy."
const FOOTER =
  'Now ready your sword! Prepare your book of spells! Adventure forth, learn something new and have loads of fun!'
const ADDCHAR_MS = 150
const SCROLL_TO_BOTTOM = 100000

export class AboutHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { title: '', content: '', footer: '' }
    this.root = React.createRef()
    this.text = React.createRef()
    this.animated = false
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      (entries) => this.startSpeakingAnimation(entries),
      { threshold: 0.9 }
    )
    this.observer.observe(this.root.current)
  }

  startSpeakingAnimation(entries) {
    if (this.animated) return
    const [entry] = entries
    if (!entry.isIntersecting) return

    this.root.current.classList.add(styles.animate)
    this.timerID = setInterval(() => this.updateMessageText(), ADDCHAR_MS)
    this.animated = true
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  updateMessageText() {
    if (
      this.state.title === TITLE &&
      this.state.content === CONTENT &&
      this.state.footer === FOOTER
    )
      return

    if (this.state.title !== TITLE) {
      const title = this.maybeIncreaseText(this.state.title, TITLE)
      this.setState({ title })
    } else if (this.state.content !== CONTENT) {
      const content = this.maybeIncreaseText(this.state.content, CONTENT)
      this.setState({ content })
    } else {
      const footer = this.maybeIncreaseText(this.state.footer, FOOTER)
      this.setState({ footer })
    }
    this.maybeScroll()
  }

  maybeIncreaseText(text, template) {
    if (text.length < template.length) {
      const increase = getRandomInteger({ min: 1, max: 3 })
      const newLength = text.length + increase
      return template.slice(0, newLength)
    }
    return template
  }

  maybeScroll() {
    this.text.current.scrollTop = SCROLL_TO_BOTTOM
  }

  render() {
    return (
      <section
        ref={this.root}
        className={`${styles.container} ${styles.background}`}
      >
        <section className={`${styles.pixelart} ${styles.light}`}>
          <div className={styles.image}></div>
        </section>
        <section className={`${styles.pixelart} ${styles.throne}`}>
          <div className={styles.image}></div>
        </section>
        <section className={styles.message}>
          <StaticImage
            src="../../../static/images/pixelart/conan-face.png"
            alt="Conan's avatar"
            quality={100}
            layout="fullWidth"
            className={styles.avatar}
          />
          <aside ref={this.text} className={styles.text}>
            <h1>{this.state.title}</h1>
            <p>{this.state.content}</p>
            <p className={styles.prompt}>{this.state.footer}</p>
          </aside>
        </section>
      </section>
    )
  }
}
