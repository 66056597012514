import React, { ReactFragment } from 'react'
import { HomeHeader } from '../header/HomeHeader'
import Header from '../header/Header'
import { Featured } from '../featured/Featured'
import { FeaturedCategories } from '../featured/FeaturedCategories'
import { LatestNotes } from '../notes/LatestNotes'
import FooterLinks from '../footerlinks/FooterLinks'
import { Series } from '../series/Series'
import Newsletter from '../newsletter/Newsletter'
import Books from '../books/Books'
import Copyright from '../copyright/Copyright'
import * as styles from './Layout.module.css'
// Code theme style
import 'prismjs/themes/prism-okaidia.css'

import '../../fonts/laffayette_comic_pro-webfont.woff2'
import '../../fonts/laffayette_comic_pro-webfont.woff'
import '../../fonts/laffayette_comic_pro-webfont.svg'
import '../../fonts/laffayette_comic_pro-webfont.eot'
import '../../fonts/laffayette_comic_pro-webfont.ttf'
import './font-face.css'
import './styles.css'

export class HomeLayout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <section className={styles.base}>
        <HomeHeader />
        <Header />
        <div className={styles.greenDivider} />
        <div className={styles.blackDivider} />
        <section className={styles.content}>
          <Featured />
          <Series />
          {children}
        </section>
        <footer>
          <div className={styles.blackDivider} />
          <div className={styles.greenDivider} />
          <div className={styles.blackDivider} />
          <LatestNotes />
          <div className={styles.blackDivider} />
          <div className={styles.greenDivider} />
          <FeaturedCategories />
          <section className={styles.footer}>
            <h1>And More Cool Stuff On This Site...</h1>
            <FooterLinks />
          </section>
          <Newsletter />
          <Books />
          <Copyright />
        </footer>
      </section>
    )
  }
}
