import React from 'react'
import { IntroHeader } from './IntroHeader'
import { AboutHeader } from './AboutHeader'
import { NewHeader } from './NewHeader'

export class HomeHeader extends React.Component {
  render() {
    return (
      <header style={{ paddingBottom: '5rem', backgroundColor: 'black' }}>
        <IntroHeader />
        <AboutHeader />
        <NewHeader />
      </header>
    )
  }
}
